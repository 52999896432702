<template>
  <b-container fluid>
    <b-row id="customer-summary">
      <b-col md="12">
        <b-row class="p-2 mb-3 column-shadow">
          <b-col md="4" class="text-center">
            <h6 class="font-weight-bold">{{$t('CurrentStock')}}</h6>
            <h3 class="text-info">{{stockState.currentStock}}</h3>
          </b-col>
          <b-col md="4" class="text-center">
            <h6 class="font-weight-bold">{{$t('TotalPurchase')}}</h6>
            <h3 class="text-success">{{stockState.purchases}}</h3>
          </b-col>
          <b-col md="4" class="text-center">
            <h6 class="font-weight-bold">{{$t('TotalSpending')}}</h6>
            <h3 class="text-danger">55{{stockState.outgoings}}</h3>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12" class="p-2 mb-3 column-shadow">
            <div class="table-responsive" style="height:400px; overflow-y:scroll;">
              <table class="table mb-0  table-hover table-hover" v-if="productStocks">
                <thead>
                  <tr>
                    <th class="text-left" scope="col">{{$t('PayDate')}}</th>
                    <th class="text-left" scope="col">{{$t('TransactionDate')}}</th>
                    <th class="text-left" scope="col">{{$t('Customer')}}</th>
                    <th class="text-center" scope="col">{{$t('StockQuantity')}}</th>
                    <th class="text-center" scope="col">{{$t('Notes')}}</th>
                    <th class="text-center" scope="col">{{$t('ReferenceCode')}}</th>
                    <th class="text-right" scope="col">{{$t('Source')}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="productStock in productStocks">
                    <td class="text-left">{{productStock.payDate | dateFormat}}</td>
                    <td class="text-left">{{productStock.createdLocalStr}}</td>
                    <td style="text-align:left;">
                      <span v-if="productStock.customerName && productStock.customerName.length > 0">
                        <i class="fa fa-user"></i> {{productStock.customerName}}
                      </span>
                      <span v-else>&nbsp;</span>
                    </td>
                    <td class="text-center">
                      <span v-if="productStock.direction=='-'" class="text-center text-danger font-weight-bold">
                        {{productStock.stock}}
                      </span>
                      <span v-else class="text-center text-success font-weight-bold">
                        {{productStock.stock}}
                      </span>
                    </td>
                    <td class="text-center">{{productStock.description || ''}}</td>
                    <td class="text-center">{{productStock.refCode}}</td>
                    <td class="text-right">
                      <span v-if="productStock.isManualOperation">{{$t('ManualStockUpdate')}}</span>
                      <span v-else-if="productStock.isStockTransfer">{{$t('StockTransfer')}}</span>
                      <span v-else-if="productStock.billID !== null">
                        <router-link :to="{ name: 'bill.edit', params: { billId: productStock.billID } }" el="a" target="_blank">
                          <b-button variant="outline-primary"><i class="fa fa-external-link-alt"></i> {{$t('Bill')}}</b-button>
                        </router-link>
                      </span>
                      <span v-else-if="productStock.appointmentID !== null" class="d-flex justify-content-end">
                        <router-link :to="{ name: 'appointment.edit', params: { appointmentId: productStock.appointmentID } }" el="a" target="_blank">
                          <b-button variant="outline-primary"><i class="fa fa-external-link-alt"></i> {{$t('Appointment')}}</b-button>
                        </router-link>
                      </span>
                      <span v-else>{{$t('FirstStock')}}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-col>
          <b-col md="12" class="p-2 mb-1 column-shadow">
            <b-form class="d-flex align-items-center">
              <b-col md="5">
                <b-form-group :label="$t('NewStockQuantity')" label-for="newStock">
                  <b-form-input id="newStock" type="text" v-model="newStock" @keypress="$root.isNumber"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="5">
                <b-form-group :label="$t('Notes')" label-for="description">
                  <b-form-input id="description" type="text" v-model="description"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="2" class="d-flex justify-content-end">
                <b-overlay :show="isSubmitting"
                           spinner-small
                           spinner-variant="primary"
                           class="d-inline-block">
                  <b-button :disabled="isSubmitting"
                            variant="primary"
                            @click="updateStock()">
                    {{ $t('Update') }}
                  </b-button>
                </b-overlay>
              </b-col>
            </b-form>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
    import ProductService from '@/services/product.js';
    import { refreshSmartDuoData } from '@/helpers/dataRefresher';

    export default {
        data() {
            return {
                isSubmitting: false,
                stockState: {},
                productStocks: [],
                newStock: null,
                description: '',
            };
        },
        methods: {
            getProductStockState() {
                ProductService.getProductStockState(this.productID)
                    .then(response => {
                        this.stockState = response;
                        this.$store.dispatch('Modal/setCurrentModalTitle', this.stockState.productName);
                    });
            },
            getProductStockInformation() {
                ProductService.getProductStockInformation(this.productID)
                    .then(response => {
                        this.productStocks = response;
                    });
            },
            updateStock() {
                this.isSubmitting = true;
                ProductService.updateProductStock(this.productID, this.newStock, this.description)
                    .then(response => {                       
                        if (response) {
                            this.$toastr.success(this.$t("Success"));
                            this.getProductStockState();
                            this.getProductStockInformation();
                            this.newStock = null;
                            this.description = '';
                            refreshSmartDuoData();
                        } else {
                            this.$toastr.error(this.$t("Error"));
                        }
                    })
                    .finally(() => {
                        //this.$router.go(0);
                        this.isSubmitting = false;
                    });
            }
        },
        watch: {
            newStock: function (val) {
                if (val)
                    this.newStock = val.toString().replace(',', '.');
            },
        },
        computed: {
            productID: function () { return this.$router.currentRoute.params.productId; }
        },
        mounted: function () {
            if (this.productID) {
                this.getProductStockState();
                this.getProductStockInformation();
            }
        }
    };
</script>
<style scoped>
  .column-shadow {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  }
</style>
